import React, { Component } from 'react'
import { Grid, Input, Accordion, Dropdown, Checkbox, Image, Radio, Segment, Button, Label, Progress, Header, Form, Table, Icon, SegmentGroup, Message, Select, TextArea, Divider } from 'semantic-ui-react'
import PageHeader from '../PageHeader.jsx'
import { withRouter, Link } from "react-router-dom";
import { withTranslation } from "react-i18next";
import ajax from '../../commons/agent.js'
import utility from '../../commons/utility.js'
import DatePicker from "react-datepicker";
import DisplayMessage from '../../commons/displayMessage.jsx'
import Footer from '../footer.jsx';
import MenuHeader from '../navbar.jsx';
import UserDetails from '../../userInfo/UserDetails.js';
import { isValidSession, getAllSessionParameters, decodeIdToken } from "../Login/actions/session";
import ReCAPTCHA from "react-google-recaptcha"
import FileUploadNew from '../../commons/fileupload/FileUploadNew.jsx';

const defFormData = {
    email: '',
    confirmEmailError: '',
    confirmEmail: '',
    problemRelatedTo: '',
    description: '',
    files: [],
    captchaVerified: false,
    error: {},
}

export class HelpdeskQuery extends Component {
    constructor(props) {
        super(props);
        this.state = {
            messageType: '', messageHeader: '', message: [],

            problemRelatedToOptions: [],
            ...defFormData,
        };

        this.captchaRef = React.createRef()

    }

    componentDidMount() {
        isValidSession() && this.props.history.push('/dashboard/helpdeskQuery')
        window.scrollTo(0, 0)
        this.onLoad()
    }


    onLoad = () => {
        this.setState({ isFetchingProblemsRelatedTo: true, })
        ajax.HelpdeskService.fetchHelpdeskProblems().end((err, res) => {
            this.setState({ isFetchingProblemsRelatedTo: false })
            if (!err && res) {
                this.setState({ problemRelatedToOptions: res.body.map(item => ({ key: item.problemId, text: item.problemDesc, value: item.problemId })) })
            }
            else {
                utility.loadDisplayMessage(this, "error", "Error", ['Something Went Wrong'])
            }
        })
    }

    reserForm = () => {
        this.setState({ ...defFormData })
        this.captchaRef.reset()
    }

    handleTextChange = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }
    handleRadioChange = (name, value) => {
        this.setState({ [name]: value })
    }
    handleDropdownChange = (name, value) => {
        this.setState({ [name]: value })
    }

    validateEmail = () => {
        let error = ''
        const value = this.state.email
        if (!value) error = this.props.t('requiredValidationRule', { what: this.props.t('email') })

        this.setState(prevState => ({ error: ({ ...prevState.error, email: error }) }))
        return error
    }

    validateConfirmEmail = () => {
        let error = ''
        const value = this.state.confirmEmail
        if (!value) error = this.props.t('requiredValidationRule', { what: this.props.t('confirmEmail') })
        if (value && value !== this.state.email) error = 'Email does not match'

        this.setState(prevState => ({ error: ({ ...prevState.error, confirmEmail: error }) }))
        return error
    }


    validateProblemRelatedTo = () => {
        let error = ''
        const value = this.state.problemRelatedTo
        if (!value) error = this.props.t('requiredValidationRule', { what: this.props.t('problemRelatedTo') })

        this.setState(prevState => ({ error: ({ ...prevState.error, problemRelatedTo: error }) }))
        return error
    }
    validateDescription = () => {
        let error = ''
        const value = this.state.description
        if (!value) error = this.props.t('requiredValidationRule', { what: this.props.t('description') })

        this.setState(prevState => ({ error: ({ ...prevState.error, description: error }) }))
        return error
    }

    validateAll = (Vo) => {
        this.validateEmail()
        this.validateConfirmEmail()
        this.validateProblemRelatedTo()
        this.validateDescription()


        return (
            this.validateEmail() ||
            this.validateConfirmEmail() ||
            this.validateProblemRelatedTo() ||
            this.validateDescription()
        )
    }
    submit = () => {
        let self = this
        let Vo = {
            emailId: this.state.email,
            problemId: this.state.problemRelatedTo,
            problemDetails: this.state.description,
            fileAttached: self.state.files && self.state.files.length > 0,
        }

        if (this.validateAll(Vo)) {
            utility.loadDisplayMessage(self, "error", "Invalid Input", ['Fill the required form fields with valid data.'])
        }
        else {
            self.setState({ isSubmitting: true, })
            ajax.HelpdeskService.raiseQueryGuest(Vo).end((err, res) => {
                self.setState({ isSubmitting: false })
                if (!err && res) {
                    let fileList = self.state.files
                    !(fileList.length > 0) && self.reserForm()
                    utility.loadDisplayMessage(self, "success", "Success", ['Your query has been raised successfully.'])
                    if (fileList.length > 0) {

                        let formData = new FormData();
                        formData.append("referenceCode", res.body.issueId)
                        for (let i = 0; i < fileList.length; i++) formData.append("files", fileList[i])
                        ajax.HelpdeskService.uploadfiles(formData).end((err, res) => {
                            if (!err && res) {
                                self.reserForm()
                                // self.setState({ isSubmitting: false })
                                // utility.loadDisplayMessage(self, "success", "Success", ['Your query has been raised successfully.'])
                            }
                            else {
                                // utility.loadDisplayMessage(self, "error", "Error", ['Something Went Wrong'])
                            }
                        })
                    }

                }
                else {
                    utility.loadDisplayMessage(self, "error", "Error", ['Something Went Wrong'])
                }
            })
        }

    }


    render() {
        const { t } = this.props
        return (
            <>
                <Grid>
                    <Grid.Column width={16} className="pb-0">
                        <MenuHeader />
                    </Grid.Column>
                </Grid>

                <Segment basic className=''>
                    <PageHeader />
                    <DisplayMessage context={this} messageType={this.state.messageType} messageHeader={this.state.messageHeader} message={this.state.message} />


                    <Grid centered>
                        <Grid.Column computer={10} tablet={14} mobile={16} className='mx-1'>
                            <Segment className='px-2' >
                                <p className='textBig colPrimary mb-1'>Details about your query</p>
                                <div>
                                    <p className='textNormal textBold mb-5px'>Guidance Notes</p>
                                    <p className='textSmall colGrey40 mb-0'>Please provide as much details as possible about your query, including which service you are trying to access.</p>
                                    <p className='textSmall colGrey40 mb-0'>Please provide a valid email address. We'll update you about your helpdesk query via email.</p>
                                    <p className='textSmall colGrey40 mb-1'>All fields marked with <Icon color='red' size='small' name='asterisk' />are mandatory.</p>
                                </div>
                                <Form size='small'>
                                    <Form.Group widths='equal'>
                                        <Form.Field>
                                            <Form.Input name='email' value={this.state.email} onChange={(e) => this.handleTextChange(e)} fluid label={t('email')} placeholder={t('email')} onBlur={() => this.validateEmail()} required />
                                            {this.state.error.email && <div className='errorText' width='50%'>{this.state.error.email}</div>}
                                        </Form.Field>
                                        <Form.Field>
                                            <Form.Input onPaste={(e) => { e.preventDefault(); utility.loadDisplayMessage(this, "warning", "Please type your email address", ['']) }} name='confirmEmail' value={this.state.confirmEmail} onChange={(e) => this.handleTextChange(e)} fluid label={t('confirmEmail')} placeholder={t('confirmEmail')} onBlur={() => this.validateConfirmEmail()} required />
                                            {this.state.error.confirmEmail && <div className='errorText' width='50%'>{this.state.error.confirmEmail}</div>}
                                        </Form.Field>
                                    </Form.Group>
                                    <Form.Group widths='equal'>
                                        <Form.Field required>
                                            <label>{t('problemRelatedTo')}</label>
                                            <Select
                                                fluid search selection placeholder={t('problemRelatedTo')}
                                                loading={this.state.isFetchingProblemsRelatedTo}
                                                options={this.state.problemRelatedToOptions} value={this.state.problemRelatedTo}
                                                onChange={(e, { value }) => this.handleDropdownChange('problemRelatedTo', value)}
                                                onBlur={() => this.validateProblemRelatedTo()}
                                            />
                                            {this.state.error.problemRelatedTo && <div className='errorText'>{this.state.error.problemRelatedTo}</div>}
                                        </Form.Field>
                                    </Form.Group>
                                    <Form.Group widths='equal'>
                                        <Form.Field required>
                                            <label>{t('description')}</label>
                                            <TextArea rows={2} name='description' value={this.state.description} onChange={(e) => this.handleTextChange(e)} label={t('description')} placeholder={t('description')} onBlur={() => this.validateDescription()} required />
                                            {this.state.error.description ? <div className='errorText'>{this.state.error.description}</div> : <div className='validationInfoText'>{t('characterLimitValidationRule', { from: '50', to: '2000' })}</div>}
                                        </Form.Field>

                                    </Form.Group>
                                </Form>

                                <Divider hidden />
                                <FileUploadNew
                                    context={this}
                                    name='files'
                                    title='Upload Documents'
                                    fileTypesAllowedRegex={/(\.jpg|\.jpeg|\.png|\.doc|\.docx|\.pdf)$/i}
                                    fileTypesAllowed={' .jpg, .jpeg, .png, .doc, .docx, .pdf'}
                                    maxFilesAllowed={5}
                                    maxFileSize={2}
                                />

                                <Grid>
                                    <Grid.Column computer={8} className='' textAlign='right'>
                                        <ReCAPTCHA
                                            ref={e => (this.captchaRef = e)}
                                            sitekey="6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI"
                                            onChange={(val) => this.setState({ captchaVerified: true })}
                                        />
                                    </Grid.Column>
                                    <Grid.Column computer={8} className='' textAlign='right' verticalAlign='bottom'>
                                        <Button className='customBtn1Secondary mr-1' onClick={() => this.reserForm()}>Reset</Button>
                                        <Button disabled={!this.state.captchaVerified} className='customBtn1Primary mr-0' loading={this.state.isSubmitting} onClick={() => this.submit()}>{t('submit')}</Button>
                                    </Grid.Column>
                                </Grid>


                            </Segment>

                        </Grid.Column>
                    </Grid>

                </Segment>

                <Footer />
            </>
        )
    }
}

export default withTranslation()(withRouter(HelpdeskQuery))

