import React, { Component } from 'react'
import { Grid, Input, Accordion, Dropdown, Checkbox, Image, Radio, Segment, Button, Label, Progress, Header, Form, Table, Icon, SegmentGroup, Message, Select, TextArea } from 'semantic-ui-react'
import PageHeader from '../PageHeader.jsx'
import { withRouter, Link } from "react-router-dom";
import { withTranslation } from "react-i18next";
import ajax from '../../commons/agent.js'
import utility from '../../commons/utility.js'
import DatePicker from "react-datepicker";
import DisplayMessage from '../../commons/displayMessage.jsx'
import Footer from '../footer.jsx';
import MenuHeader from '../navbar.jsx';
import UserDetails from '../../userInfo/UserDetails.js';
import { isValidSession, getAllSessionParameters, decodeIdToken } from "../Login/actions/session";

export class Help extends Component {
   constructor(props) {
      super(props);
      this.state = {
         messageType: '', messageHeader: '', message: [],



      };

   }

   componentDidMount() {
      isValidSession() && this.props.history.push('/dashboard/help')
      window.scrollTo(0, 0)
      this.onLoad()
   }


   onLoad = () => {
   }


   render() {
      const { t } = this.props
      return (
         <>
            <Grid>
               <Grid.Column width={16} className="pb-0">
                  <MenuHeader />
               </Grid.Column>
            </Grid>

            <Segment basic className=''>
               <PageHeader />
               <DisplayMessage context={this} messageType={this.state.messageType} messageHeader={this.state.messageHeader} message={this.state.message} />


               <Grid centered>
                  <Grid.Column className='mx-1'>
                     <Segment className='px-2' >
                        <p className='textBig colPrimary'>Welcome to the dcn.scot Help Centre. Need help, we will get you to the right place. </p>
                        <p className='textNormal textBold'>Please note: If you are a member of the public wanting to know about a Dog Control Notice, you will need to contact your local council. Dcn.scot is only accessible by authorised local authority and Police Scotland officers. </p>
                        <p className='textNormal'>We know sometimes you will need to contact us about dcn.scot, and we want to make sure we get you in touch with the right team for the job. But nobody wants to wait for an answer if we can help you find the answer yourself. </p>
                        <p className='textNormal'>That is why you will find some handy Frequently Asked Questions that deal with all kinds of issues and queries below. Most of our users who use our online FAQs get the help they need before even having to get in touch. </p>
                        <p className='textNormal'>But if that does not work, do not worry, you will be able to get in contact with us by raising a query online with our Helpdesk depending on what you need. </p>
                        <p className='textNormal'>Just pick which heading covers the area you need help with and select which FAQ covers your query. If you find your query is still unresolved, we will give you the correct contact details for the Helpdesk Team. </p>

                        <p className='textBig textBold'>What do you need help with? </p>

                        <div className='ml-2'>
                           <p className='textNormal colPrimary textBold'>Locked out or forgotten username</p>
                           <p className='textNormal textBold mb-0'>What happens if I forget my username? </p>
                           <p className='textNormal'>If you forget your username, click on the sign in button on the dcn.scot homepage and select the option for 'Forgot Username' to retrieve your forgotten username. </p>
                           <p className='textNormal textBold mb-0'>What happens if I forget my password? </p>
                           <p className='textNormal'>If you forget your password, click on the sign in button on the dcn.scot homepage and select the option for “Forgot Password.” A new one time use password will then be sent to you by email. </p>
                        </div>

                       

                        <Grid>
                           <Grid.Column computer={16} className='mt-4' textAlign='center'>
                           <p className='textNormal colPrimary textBold'>Above sections have not answered my query </p>
                           <p className='textNormal textBold'>If you still need help, you can contact our helpdesk team, via the Contact Helpdesk button below.  </p>

                              <Button as={Link} to='/helpdeskQuery' className='customBtn1Primary mr-0' >CONTACT HELPDESK </Button>
                           </Grid.Column>
                        </Grid>


                     </Segment>

                  </Grid.Column>
               </Grid>

            </Segment>

            <Footer />
         </>
      )
   }
}

export default withTranslation()(withRouter(Help))

