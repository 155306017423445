import React, { Component } from 'react'
import { Grid, Input, Accordion, Dropdown, Checkbox, Image, Radio, Segment, Button, Label, Progress, Header, Form, Table, Icon, SegmentGroup, Message, Select, TextArea } from 'semantic-ui-react'
import PageHeader from '../PageHeader.jsx'
import { withRouter, Link } from "react-router-dom";
import { withTranslation } from "react-i18next";
import ajax from '../../commons/agent.js'
import utility from '../../commons/utility.js'
import DatePicker from "react-datepicker";
import DisplayMessage from '../../commons/displayMessage.jsx'
import Footer from '../footer.jsx';
import MenuHeader from '../navbar.jsx';
import UserDetails from '../../userInfo/UserDetails.js';
import { isValidSession, getAllSessionParameters, decodeIdToken } from "../Login/actions/session";

export class HelpPostLogin extends Component {
   constructor(props) {
      super(props);
      this.state = {
         messageType: '', messageHeader: '', message: [],



      };

   }

   componentDidMount() {
      !isValidSession() && this.props.history.push('/help')
      window.scrollTo(0, 0)
      this.onLoad()
   }


   onLoad = () => {
   }

   render() {
      const { t } = this.props
      return (
         <>

            <Segment basic className=''>
               <PageHeader />
               <DisplayMessage context={this} messageType={this.state.messageType} messageHeader={this.state.messageHeader} message={this.state.message} />


               <Grid centered>
                  <Grid.Column className='mx-1'>
                     <Segment className='px-2' >
                        <p className='textBig colPrimary'>Welcome to the dcn.scot Help Centre. Need help, we will get you to the right place. </p>
                        <p className='textNormal'>We know sometimes you will need to contact us about dcn.scot, and we want to make sure we get you in touch with the right team for the job. But nobody wants to wait for an answer if we can help you find the answer yourself. </p>
                        <p className='textNormal'>That is why you will find some handy Frequently Asked Questions that deal with all kinds of issues and queries below. Most of our users who use our online FAQs get the help they need before even having to get in touch. </p>
                        <p className='textNormal'>But if that does not work, do not worry, you will be able to get in contact with us by raising a query online with our Helpdesk depending on what you need.</p>
                        <p className='textNormal'>Just pick which heading covers the area you need help with and select which FAQ covers your query. If you find your query is still unresolved, we will give you the correct contact details for the Helpdesk Team. </p>

                        <p className='textBig textBold'>What do you need help with? </p>

                        <div className='ml-2'>
                           <p className='textNormal textBold mb-0'>What happens if I forget my username? </p>
                           <p className='textNormal'>If you forget your username, click on the sign in button on the dcn.scot homepage and select the option for 'Forgot Username' to retrieve your forgotten username.</p>

                           <p className='textNormal textBold mb-0'>What happens if I forget my password? </p>
                           <p className='textNormal'>If you forget your password, click on the sign in button on the dcn.scot homepage and select the option for “Forgot Password.” A new one time use password will then be sent to you by email. </p>

                           <p className='textNormal textBold mb-0'>How do I register another super agent for our council? </p>
                           <p className='textNormal'>Super agent registration forms are available on the dcn.scot Knowledge Hub Group. An existing super agent in your council already set up with access will need to forward the registration form to the Improvement Service – please attach it to a Helpdesk Query and our Customer Service team will set it up. </p>
                           <p className='textNormal textBold mb-0'>How do I register another agent for our council to access dcn.scot? </p>
                           <p className='textNormal'>Agent accounts need to be set up by the super agent for your council. The super agent can register and approve additional agent accounts. </p>

                           <p className='textNormal textBold mb-0'>Can I upload photos of the actual dog? </p>
                           <p className='textNormal'>Not at the moment. Currently dcn.scot will show a stock photo of a typical dog for each specific dog breed. This will assist dcn.scot users who are less familiar with dog breeds, what it could potentially look like. If all councils would like to be able to upload a photo of the actual dog, this could be looked into as an additional platform development. </p>

                           <p className='textNormal textBold mb-0'>Dcn.scot is missing a photo for a specific dog breed – can you add one? </p>
                           <p className='textNormal'>If you think we’re missing a specific dog breed from the options, please raise a support request, and we’ll look to add it. </p>

                           <p className='textNormal textBold mb-0'>How do I transfer a DCN to another council area?</p>
                           <p className='textNormal'>Please refer to the guidance document which contains more details on the transfer process.</p>

                           <p className='textNormal textBold mb-0'>Do you have any training materials on how to use dcn.scot? </p>
                           <p className='textNormal'>Yes – all councils can access dcn.scot training materials on the Knowledge Hub group which you can then download.  </p>

                           <p className='textNormal textBold mb-0'>We have new members of the team who need access to the Knowledge Hub group – how can they sign up? </p>
                           <p className='textNormal'>The dcn.scot Knowledge Hub group is a private group, so we will need to issue an invite to your colleagues who need access. Please raise a support request on dcn.scot asking for an invite to be sent for the K Hub group – you will need to provide their e-mail addresses. </p>
                        </div>



                        <Grid>
                           <Grid.Column computer={16} className='mt-4' textAlign='center'>
                              <p className='textNormal colPrimary textBold'>Above sections have not answered my query </p>
                              <p className='textNormal textBold'>If you still need help, you can contact our helpdesk team, via the Contact Helpdesk button below.  </p>

                              <Button as={Link} to='/dashboard/helpdeskQuery' className='customBtn1Primary mr-0'>CONTACT HELPDESK </Button>
                           </Grid.Column>
                        </Grid>


                     </Segment>

                  </Grid.Column>
               </Grid>

            </Segment>
         </>
      )
   }
}

export default withTranslation()(withRouter(HelpPostLogin))

