import React, { Component } from "react";
import { Input, Menu, Button, Image, Dropdown, Search, Icon, Header, Label, Grid, Modal } from "semantic-ui-react";
import { BrowserRouter as Router, Switch, Route, Link, withRouter } from "react-router-dom";
// import UserDetails from '../userInfo/UserDetails.js';
import { dispatchLogout } from "../../src/components/Login/actions/sign-out";
import util from '../commons/utility.js';
import TopNotifications from './notifications/topNotificationsList.jsx';
import ajax from '../commons/agent.js'
import { withTranslation } from "react-i18next";
import UserDetails from '../userInfo/UserDetails.js';
import utility from "../commons/utility.js";


class DmpNavbar extends Component {
  constructor(props) {
    super(props)

    this.state = {
      activeItem: "My Workspace",
      noOfUnreadNotifications: null,
    }
    this.fetchNotificationCount = this.fetchNotificationCount.bind(this)
  }
  componentDidMount() {
    var self = this;
    var userId = util.fetchUserId();
    this.fetchNotificationCount()
    setInterval(this.fetchNotificationCount, 60000);
  }
  fetchNotificationCount() {
    var self = this;
    ajax.Notifications.fetchMyTotalUnreadNotifications().end((err, response) => {
      //utility.handleErrors( err );
      if (err && err.response && err.response.status === 401) {
        self.setState({ modalOpen: true });
      }

      if (!err && response) {
        self.setState({ noOfUnreadNotifications: response.body, modalOpen: false })
      }
    })
  }


  handleItemClick = (e, { name }) => this.setState({ activeItem: name });

  /**
   * Handles logout button click
  */
  handleLogoutBtnClick = () => {
    dispatchLogout();
  };
  render() {
    const { activeItem } = this.state;
    const { t } = this.props
    const orgCategory = this.props.fetchOrganizationCategory(utility.fetchUserBpid())

    return (
      <>
        <Modal open={this.state.modalOpen} dimmer='inverted'>
          <Modal.Content>
            <Modal.Description>
              <Header>Session expired, please login again.</Header>

            </Modal.Description>
          </Modal.Content>
          <Modal.Actions>
            <Button className='customBtn1Primary' onClick={this.handleLogoutBtnClick.bind(this)} >
              <Icon name='sign in alternate' /> {t('logout')}
            </Button>
          </Modal.Actions>
        </Modal>
        <Menu secondary className="bgHeader bgNav pr-0 dmpNavigation px-1">
          <Menu.Item
            className="brand dmpBrand" name="Scottish Dog Control Database"
            as={Link} to='/dashboard' onClick={this.handleItemClick}
          >
            <Image src='/images/dcn.scot.png' style={{ height: '74px' }} />
          </Menu.Item>


          <Menu.Menu position="right">
            <Menu.Item
              name={t('dashboard')} as={Link} to="/dashboard"
              active={activeItem === "Dashboard"} onClick={this.handleItemClick}
            />
            <Menu.Item
              name={t('organisations')} as={Link} to="/dashboard/organisations"
              active={activeItem === "Organisations"} onClick={this.handleItemClick}
            />    

            {!UserDetails.platformadmin &&
              <>
                <Menu.Item
                  name={t('searchADCN')} as={Link}
                  to="/dashboard/dogControlNotices"
                  active={activeItem === "dogControlNotices"} onClick={this.handleItemClick}
                />
              </>
            }

            {orgCategory === 'Local Authority' &&
              <>
                < Menu.Item
                  name="Transfer Requests" as={Link} to="/dashboard/transferRequests"
                  active={activeItem === "transferRequests"} onClick={this.handleItemClick}
                />
                < Menu.Item
                  name="Upload DCN" as={Link} to="/dashboard/uploadDcn"
                  active={activeItem === "uploadDcn"} onClick={this.handleItemClick}
                />

                {/* <Dropdown item text={t('create')}>
                  <Dropdown.Menu>
                    <Dropdown.Item as={Link} to="/dashboard/uploadDcn" text='Upload DCN' />
                    <Dropdown.Item as={Link} to="/dashboard/bulkUpload" text='Bulk Upload' />
                  </Dropdown.Menu>
                </Dropdown> */}
              </>
            }

            <Menu.Item
              name={t('helpdeskQueryDashboard')} as={Link}
              to="/dashboard/helpdeskQueryDashboard"
              active={activeItem === "Helpdesk Query Dashboard"} onClick={this.handleItemClick}
            />

            {(orgCategory === 'Improvement Service') &&
              <>
                <Menu.Item
                  name={t('reports')} as={Link}
                  to="/dashboard/reports"
                  active={activeItem === "reports"} onClick={this.handleItemClick}
                />
              </>
            }

            <Menu.Item
              name={t('help')} as={Link}
              to="/help"
              active={activeItem === "help"} onClick={this.handleItemClick}
            />

            <Dropdown
              item closeOnChange={false} direction='left' icon='bell outline'
              trigger={
                <span>
                  {this.state.noOfUnreadNotifications > 0 && <Label color='red' style={{ top: '3px', padding: '4px 5px', fontSize: '0.76em', fontWeight: '400' }} floating size="mini">{this.state.noOfUnreadNotifications || 0}</Label>}
                </span>
              }
              options={[
                {
                  key: 1,
                  text: <div style={{ margin: '-11px -16px', background: '#fff', borderRadius: '2px', boxShadow: 'rgb(86 86 86 / 20%) 0px 2px 4px, rgb(86 86 86 / 8%) 0px 8px 7px' }}>
                    <TopNotifications.TopNotificationsList userId={util.fetchUserId()} context={this} fetchNotificationCount={this.fetchNotificationCount.bind(this)} />
                    <div className='text-center py-5px'>
                      {/* <Link to='/notifications'>{t('viewAll')}</Link> */}
                    </div>
                  </div>
                }
              ]}
            ></Dropdown>


            <Dropdown item text={`${util.fetchUserFirstName()}`}>
              <Dropdown.Menu>
                <Dropdown.Header>
                  <Header>
                    <Icon name='user' className='colPrimary' />
                    <Header.Content>
                      <div className='textNormal'>{util.fetchUserFirstName()}</div>
                      <Header.Subheader>{this.props.fetchOrganizationName(util.fetchUserBpid())}</Header.Subheader>
                    </Header.Content>
                  </Header>
                </Dropdown.Header>
                <Dropdown.Divider />
                <Dropdown.Item as={Link} to={`/dashboard/organisations/${UserDetails.bpId}`}>{t('companyProfile')}</Dropdown.Item>
                {/* <Dropdown.Item as={Link} to='/help'>{t('help')}</Dropdown.Item> */}
                <Dropdown.Item onClick={this.handleLogoutBtnClick}>{t('logout')}</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>

          </Menu.Menu>
        </Menu >
      </>
    )
  }
}


export default withTranslation()(withRouter(DmpNavbar))