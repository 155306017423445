import React, { Component } from "react";
import { Header, Icon, Input, Menu, Button, Popup, Dropdown, Image } from "semantic-ui-react";
import { BrowserRouter as Router, Switch, Route, Link, withRouter } from "react-router-dom";
import { sendAuthorizationRequest, sendTokenRequest } from "./Login/actions/sign-in";
import { isValidSession, getAllSessionParameters, decodeIdToken } from "./Login/actions/session";
import ReactJson from 'react-json-view'
import { dispatchLogout } from "./Login/actions/sign-out";
import util from '../commons/utility.js';
import { withTranslation } from "react-i18next";

class MenuHeader extends Component {
  state = {
    activeItem: "home",
    idToken: {},
    tokenResponse: {},
    isLoggedIn: false,
    userName: null
  };

  handleItemClick = (e, { name }) => this.setState({ activeItem: name });

  /**
     * Handles login button click
     */
  handleLoginBtnClick = () => {
    sendAuthorizationRequest();
  };

  /**
   * Handles logout button click
   */
  handleLogoutBtnClick = () => {
    dispatchLogout();
  };
  handleHomeClick = () => {
    window.location.href = window.location.protocol + '//' + window.location.host + "/dashboard";
  }
  render() {
    const { activeItem } = this.state
    const { t } = this.props

    return (
      <Menu secondary className="bgHeader bgNav pr-0 dmpNavigation px-2">
        <Menu.Item
          className="brand dmpBrand"
          name="Scottish Dog Control Database"
          onClick={this.handleItemClick}
          as={Link} to='/'
        ><Image src='/images/dcn.scot.png' style={{ height: '74px' }} /></Menu.Item>

        <Menu.Menu position="right">

          {/* <Menu.Item
            name="about"
            active={activeItem === "about"}
            onClick={this.handleItemClick}
          />
          <Menu.Item
            name="contact"
            active={activeItem === "contact"}
            onClick={this.handleItemClick}
          />
          <Menu.Item
            name="help"
            active={activeItem === "help"}
            onClick={this.handleItemClick}
          /> */}
          {
            isValidSession() &&
            <Menu.Item
              as={Link}
              to="/dashboard"
              name="Dashboard"
              active={activeItem === "Dashboard"}
              onClick={this.handleItemClick}
            />
          }
          {
            !isValidSession() &&
            <Menu.Item
              as={Link}
              to="/help"
              name="help"
              active={activeItem === "help"}
              onClick={this.handleItemClick}
            />
          }

          {
            isValidSession() ?
              <Dropdown item text={`${util.fetchUserFirstName()}`}>
                <Dropdown.Menu>
                  <Dropdown.Item onClick={this.handleHomeClick}>Dashboard</Dropdown.Item>
                  {/* <Dropdown.Item as={Link} to='/help'>{t('help')}</Dropdown.Item> */}
                  <Dropdown.Item onClick={this.handleLogoutBtnClick}>{t('logout')}</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>

              :
              <Menu.Item
                id="loginItem"
                name="login"
                active={activeItem === "login"}
                onClick={this.handleLoginBtnClick}
              />
          }
        </Menu.Menu>
      </Menu>
    );
  }
}
export default withTranslation()(withRouter(MenuHeader))
