import React from 'react'
import { withRouter, Link } from 'react-router-dom';
import utility from '../../commons/utility.js';
import ajax from '../../commons/agent.js';
import { Button, Input, Icon, Loader, Grid, Segment, Label, Modal, Divider, Form, TextArea, Select, Table as SemanticTable, Confirm } from 'semantic-ui-react';
import { Table } from 'antd';
import moment from 'moment'
import PageHeader from '../PageHeader.jsx';
import { withTranslation } from 'react-i18next';
import DisplayMessage from '../../commons/displayMessage.jsx';
import CustomAccordion from '../reusable/CustomAccordion.jsx';
import UserDetails from '../../userInfo/UserDetails.js';

class InboxTab extends React.Component {

   constructor(props) {
      super(props);
      this.state = {
         messageType: '', messageHeader: '', message: [],
         data: [], dataLength: 0, errorResponse: [],
         filteredInfo: null, sortedInfo: null,
         searchText: '', searchedColumn: '',
         currentDataCount: 0,
         historyList: [],

         catsubcatFilter: null,
         categoryOptions: null,

         isFetching: false,
         isFetchingFiles: false,
         remark: '',
         isSubmittingResponse: false,

         assignToOptions: [],
         assignTo: '',
         assignToBpid: '',
         assignToUserId: '',
      };
   }

   componentDidMount() {
      const myOrgCategory = this.props.fetchOrganizationCategory(utility.fetchUserBpid())
      // myOrgCategory !== 'Improvement Service' && this.props.history.push('/')

      let orgList = this.props.fetchOrganizationList()
      const myUserId = utility.fetchUserId()
      const myBpId = utility.fetchUserBpid()
      let orgOptions = []
      if (myOrgCategory === 'Improvement Service') {
         orgOptions = orgList.filter(item => (item.organizationCategory !== 'Improvement Service')).map(item => ({ key: item.organizationBpid, text: `${item.organizationName}`, value: item.organizationBpid }))
      }
      else {
         orgOptions = orgList.filter(item => (item.organizationCategory === 'Improvement Service')).map(item => ({ key: item.organizationBpid, text: `${item.organizationName}`, value: item.organizationBpid }))
      }

      this.setState({ isFatchingAgentsDetails: true })
      ajax.OrganizationMaster.fetchOrganizationByBpId(myBpId).end((err, res) => {
         this.setState({ isFatchingAgentsDetails: false })
         if (!err && res) {
            this.setState({ myOrgUserOptions: res.body.organizationUsersVo.filter(item => item.userId !== myUserId).map(item => ({ key: item.userId, text: `${item.firstName} ${item.lastName}`, value: item.userId })) })
         }
         else {
            utility.loadDisplayMessage(this, "error", "Error", ['Something Went Wrong'])
         }
      })
      this.setState({ assignToOptions: orgOptions, myUserId, myBpId, myOrgCategory })
      this.pageReload();
   }
   componentDidUpdate(prevProps, prevState) {
      if (prevProps.calledFrom !== this.props.calledFrom) {
         this.pageReload()
      }
   }

   pageReload() {
      const self = this

      this.setState({ isFetching: true })
      ajax.HelpdeskService.fetchHelpdeskQueryData(this.props.calledFrom).end((err, response) => {
         this.clearAll()
         this.setState({ isFetching: false })
         let temp = utility.handleErrors(err);
         if (!err && response) {
            let data = response.body.map((item, key) => {
               // item.sellerOrganizationName = this.props.fetchOrganizationName(item.sellerBpid)
               // item.pendingWith = item.pendingWithBpid && this.props.fetchOrganizationName(item.pendingWithBpid)
               // item.lastUpdatedAt = item.lastUpdatedAt && utility.formatDate(item.lastUpdatedAt)
               item.raisedBy = item.emailId || `${this.props.fetchOrganizationName(item.complainantBpid)} (${item.complainantName})`
               item.assignedTo = `${this.props.fetchOrganizationName(item.assignedToBpid)}${item.assignedToUserName ? ` (${item.assignedToUserName})` : ''}`
               return item
            })
            self.setState({ data: data, dataLength: data.length })
         }
         else {
            this.setState({ errorResponse: temp });
         }
      })


      this.setState({ isFetchingProblemsRelatedTo: true, })
      ajax.HelpdeskService.fetchHelpdeskProblems().end((err, res) => {
         this.setState({ isFetchingProblemsRelatedTo: false })
         if (!err && res) {
            this.setState({ problemRelatedToFilterList: res.body.map(item => ({ text: item.problemDesc, value: item.problemDesc })) })
         }
         else {
            utility.loadDisplayMessage(this, "error", "Error", ['Something Went Wrong'])
         }
      })

   }



   //search start
   getColumnSearchProps = dataIndex => ({
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
         <div style={{ padding: 8 }}>
            <Input
               size='small'
               className='customInput'
               ref={node => {
                  this.searchInput = node;
               }}
               placeholder={`Search`}
               value={selectedKeys[0]}
               onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
               onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
               style={{ marginBottom: 8, display: 'block' }}
            />
            <div >
               <Button icon='redo' content='Reset' size="small" compact className='customBtn1Secondary' style={{ width: '48%' }} onClick={() => this.handleReset(clearFilters)} />
               <Button icon='search' content='Search' size="small" compact className='customBtn1Primary' style={{ width: '50%', marginRight: '0' }} onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)} />
            </div>
         </div>
      ),
      filterIcon: filtered => <Icon name='search' className={filtered ? 'colPrimary' : ''} style={{ padding: '15px 0px' }} />,
      onFilter: (value, record) =>
         record[dataIndex]
            ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
            : '',
      onFilterDropdownVisibleChange: visible => {
         if (visible) {
            setTimeout(() => this.searchInput.select(), 100);
         }
      },
      render: text => text,
   });

   handleSearch = (selectedKeys, confirm, dataIndex) => {
      confirm();
      this.setState({
         searchText: selectedKeys[0],
         searchedColumn: dataIndex,
      });
   };

   handleReset = clearFilters => {
      clearFilters();
      this.setState({ searchText: '' });
   };
   //search end 


   handleChange = (pagination, filters, sorter, currentDataSource) => {
      this.setState({
         filteredInfo: filters,
         sortedInfo: sorter,
      });
   };

   clearAll = () => {
      this.setState({
         filteredInfo: null,
         sortedInfo: null,
      });
   };

   fetchAdditionalInfo = (issueId) => {
      let self = this
      this.setState({ isFetchingFiles: true, uploadedFileList: null })
      ajax.HelpdeskService.listAllFiles(issueId).end((err, response) => {
         this.setState({ isFetchingFiles: false })
         let temp = utility.handleErrors(err);
         if (!err && response) {
            self.setState({ uploadedFileList: response.body });
         }
         else {
            this.setState({ errorResponse: temp });
         }
      })
      this.setState({ isFetchingHistory: true, historyList: [] })
      ajax.HelpdeskService.fetchIssueHistory(issueId).end((err, response) => {
         this.setState({ isFetchingHistory: false })
         let temp = utility.handleErrors(err);
         if (!err && response) {
            self.setState({ historyList: response.body });
         }
         else {
            this.setState({ errorResponse: temp });
         }
      })
   }
   downloadFile = (refId, filename) => {
      let self = this
      if (refId && filename) {
         var promise = new Promise((resolve, reject) => {
            ajax.HelpdeskService.downloadFile(refId, filename).end(async (err, response) => {
               let errJson = utility.handleErrors(err);
               if (!err && response) {
                  let msg = [];
                  resolve(response);
               } else {
                  utility.loadDisplayMessage(self, "error", "Failure.", errJson.errMessage);
               }
            })
         }).then((resolve) => {
            var headers = resolve.headers;
            var blob = new Blob([resolve.xhr.response], { type: headers['content-type'] });    //{type:headers['content-type']}
            var link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = filename;
            document.body.appendChild(link);
            link.click();
            link.remove();
            link.addEventListener("focus", w => { window.URL.revokeObjectURL(blob) });

         }).then((reject) => {
            //utility.loadDisplayMessage(self, "error", "Failure.", errJson.errMessage);
         }).finally(() => {
         })
      }
   }

   submitResponse = (issueId) => {
      let self = this
      let Vo = {
         issueId: issueId,
         remark: this.state.remark,
      }
      if (!Vo.remark) { this.setState({ confirmResponseModal: false }); utility.loadDisplayMessage(self, "error", "Invalid Input", ['Response is Required']) }
      else if (Vo.remark.length < 10) { this.setState({ confirmResponseModal: false }); utility.loadDisplayMessage(self, "error", "Invalid Input", ['Response Should be Minimum 10 characters']) }
      else if (Vo.remark.length > 2000) { this.setState({ confirmResponseModal: false }); utility.loadDisplayMessage(self, "error", "Invalid Input", ['Response Should be Maximum 2000 characters']) }
      else {
         // console.log(Vo)
         this.setState({ isSubmittingResponse: true })
         ajax.HelpdeskService.submitIssueResponse(Vo).end((err, response) => {
            this.setState({ isSubmittingResponse: false })
            let temp = utility.handleErrors(err);
            if (!err && response) {
               this.pageReload()
               this.setState({ helpdeskDetailModal: false, confirmResponseModal: false, remark: '' })
            }
            else {
               utility.loadDisplayMessage(self, "error", "Error", ['Something Went Wrong'])
            }
         })
      }
   }
   assignTicket = (issueId) => {
      let self = this
      let Vo = {
         issueId: issueId,
         assignedToUserid: this.state.assignTo === 'self' ? utility.fetchUserId() : this.state.assignTo === 'user' ? this.state.assignToUserId : null,
         assignedToBpid: this.state.assignTo === 'myCouncil' ? this.state.myBpId : this.state.assignTo === 'council' ? this.state.assignToBpid : null,
         remark: this.state.assignTo === 'self' ? '' : this.state.reassignRemark,
      }
      if (!this.state.assignTo) { this.setState({ confirmAssignModal: false }); utility.loadDisplayMessage(self, "error", "Invalid Input", [`Issue Assignment is Required`]) }
      else if (!Vo.assignedToBpid && !Vo.assignedToUserid) { this.setState({ confirmAssignModal: false }); utility.loadDisplayMessage(self, "error", "Invalid Input", [`${this.state.assignTo === 'council' ? 'Organisation' : 'User'} is Required`]) }
      else if (this.state.assignTo !== 'self' && !Vo.remark) { this.setState({ confirmAssignModal: false }); utility.loadDisplayMessage(self, "error", "Invalid Input", ['Remark is Required']) }
      else if (this.state.assignTo !== 'self' && Vo.remark.length < 10) { this.setState({ confirmAssignModal: false }); utility.loadDisplayMessage(self, "error", "Invalid Input", ['Remark Should be Minimum 10 characters']) }
      else if (this.state.assignTo !== 'self' && Vo.remark.length > 2000) { this.setState({ confirmAssignModal: false }); utility.loadDisplayMessage(self, "error", "Invalid Input", ['Remark Should be Maximum 2000 characters']) }
      else {
         // console.log(Vo)
         this.setState({ isAssigningTicket: true })
         ajax.HelpdeskService.reassignIssue(Vo).end((err, response) => {
            this.setState({ isAssigningTicket: false })
            let temp = utility.handleErrors(err);
            if (!err && response) {
               this.pageReload()
               this.setState({ assignModal: false, confirmAssignModal: false, assignTo: '', assignToBpid: '', assignToUserId: '', reassignRemark: '' })
            }
            else {
               utility.loadDisplayMessage(self, "error", "Error", ['Something Went Wrong'])
            }
         })
      }
   }
   closeIssue = (issueId) => {
      let self = this
      let Vo = {
         issueId: issueId,
         remark: this.state.closingRemark,
      }
      if (!Vo.remark) { this.setState({ confirmCloseIssueModal: false }); utility.loadDisplayMessage(self, "error", "Invalid Input", ['Remark is Required']) }
      else if (Vo.remark.length < 10) { this.setState({ confirmCloseIssueModal: false }); utility.loadDisplayMessage(self, "error", "Invalid Input", ['Remark Should be Minimum 10 characters']) }
      else if (Vo.remark.length > 2000) { this.setState({ confirmCloseIssueModal: false }); utility.loadDisplayMessage(self, "error", "Invalid Input", ['Remark Should be Maximum 2000 characters']) }
      else {
         // console.log(Vo)
         this.setState({ isClosingTicket: true })
         ajax.HelpdeskService.closeIssue(Vo).end((err, response) => {
            this.setState({ isClosingTicket: false })
            let temp = utility.handleErrors(err);
            if (!err && response) {
               this.pageReload()
               this.setState({ closeIssueModal: false, confirmCloseIssueModal: false, assignTo: '', assignToBpid: '', assignToUserId: '', closingRemark: '' })
               utility.loadDisplayMessage(self, "success", "Success", ['The service request has been closed'])
            }
            else {
               utility.loadDisplayMessage(self, "error", "Error", ['Something Went Wrong'])
            }
         })
      }
   }

   render() {
      const { t } = this.props
      let { sortedInfo, filteredInfo } = this.state;
      sortedInfo = sortedInfo || {};
      filteredInfo = filteredInfo || { status: ["Logged", "Active"] };
      const cellStyle = { color: 'var(--grey20)', padding: '10px 8px', fontSize: '.98em' }

      // const filename = 'DCNs_' + Date.now(),
      //    fields = {
      //       "dcnReferenceNo": "DCNREFERENCENO",
      //       "dogOwnerName": "DOG OWNER NAME",
      //       "dogOwnerAddress": "DOG OWNER ADDRESS",
      //       "dogName": "DOG NAME",
      //       "dogBreed": "DOG BREED",
      //       "createdAt": "CREATED AT",
      //       "dcnStatus": "DCN STATUS",
      //       // "buyerOrganizationName2": "BUYER",
      //    },
      //    style = {
      //       padding: "0px 12px", fontFamily: 'Roboto', fontSize: '0.92em', border: 'none', height: '32px', lineHeight: '30px'
      //    },
      //    text = "Download"

      const columns = [
         {
            title: 'SR ID', dataIndex: 'issueId', key: 'issueId',
            sorter: (a, b) => a.issueId - b.issueId,
            sortOrder: sortedInfo.columnKey === 'issueId' && sortedInfo.order,
            width: '8%',
            fixed: 'left',
            ellipsis: true,
            render: (actions, record) => {
               return {
                  props: { style: { ...cellStyle } },
                  children: <>
                     {record.issueId}
                  </>
               }
            }
         },
         {
            title: 'CATEGORY', dataIndex: 'problemDesc', key: 'problemDesc',
            filters: this.state.problemRelatedToFilterList || [],
            filterMultiple: true,
            filteredValue: filteredInfo.problemDesc || null,
            onFilter: (value, record) => record.problemDesc.indexOf(value) === 0,

            width: '10%',
            // fixed: 'left',
            ellipsis: true,
            render: (problemDesc, record) => {
               return {
                  props: { style: { ...cellStyle } },
                  children: <>{problemDesc}</>
               }
            }
         },
         {
            title: 'DESCRIPTION', dataIndex: 'problemDetails', key: 'problemDetails',
            ...this.getColumnSearchProps('problemDetails'),
            width: '18%',
            // fixed: 'left',
            // ellipsis: true,
            render: (problemDetails, record) => {
               return {
                  props: { style: { ...cellStyle, position: 'relative' } },
                  children: <>
                     <span className='colPrimary textNormal textBold cursorPointer' title={problemDetails} onClick={() => this.setState({ viewIssueModal: record }, () => { this.fetchAdditionalInfo(record.issueId) })}>
                        {utility.elipses(problemDetails, 28)} {record.fileAttached && <Icon name='attach' style={{ position: 'absolute', right: '5px', top: '12px', color: '#848b99', background: 'rgb(255 255 255 / 87%)' }} />}
                     </span></>
               }
            }
         },
         {
            title: 'RAISED BY', dataIndex: 'raisedBy', key: 'raisedBy',
            ...this.getColumnSearchProps('raisedBy'),
            width: '14%',
            // fixed: 'left',
            ellipsis: true,
            render: (raisedBy, record) => {
               return {
                  props: { style: { ...cellStyle } },
                  children: <>
                     {raisedBy}
                     {/* {record.emailId || `${this.props.fetchOrganizationName(record.complainantBpid)} (${record.complainantName})`} */}
                  </>
               }
            }
         },
         {
            title: 'ASSIGNED TO', dataIndex: 'assignedTo', key: 'assignedTo',
            ...this.getColumnSearchProps('assignedTo'),
            width: '14%',
            // fixed: 'left',
            ellipsis: true,
            render: (assignedTo, record) => {
               return {
                  props: { style: { ...cellStyle } },
                  children: <>
                     {assignedTo}
                  </>
               }
            }
         },
         {
            title: 'STATUS', dataIndex: 'status', key: 'status',
            filters: [{ text: 'Logged', value: 'Logged' }, { text: 'Active', value: 'Active' }, { text: 'Closed', value: 'Closed' }],
            filterMultiple: true,
            filteredValue: filteredInfo.status || null,
            onFilter: (value, record) => record.status.indexOf(value) === 0,
            width: '8%',
            // fixed: 'left',
            ellipsis: true,
            render: (status, record) => {
               return {
                  props: { style: { ...cellStyle } },
                  children: <>
                     <span title={status}>
                        {status === 'Logged' && <Label empty size='tiny' color='orange' circular></Label>}
                        {status === 'Active' && <Label empty size='tiny' color='green' circular></Label>}
                        {status === 'Closed' && <Label empty size='tiny' color='red' circular></Label>}
                        <span> {status}</span>
                     </span>
                  </>
               }
            }
         },
         {
            title: 'ACTIONS', dataIndex: 'actions', key: 'actions',
            width: '15%',
            // fixed: 'left',
            ellipsis: true,
            render: (actions, record) => {
               return {
                  props: { style: { ...cellStyle } },
                  children: <>
                     {(record.status !== 'Closed' && this.props.calledFrom === 'myInbox' && ((record.assignedToUserid && record.assignedToUserid === this.state.myUserId) || (record.createdBy === this.state.myUserId))) &&
                        <span className='textSmall textBold customLink pr-1' onClick={() => this.setState({ helpdeskDetailModal: record }, () => { this.fetchAdditionalInfo(record.issueId) })}>
                           <Icon name='reply' />Reply
                     </span>}
                     {(record.status !== 'Closed' && (record.assignedToUserid ? record.assignedToUserid === this.state.myUserId : record.assignedToBpid === this.state.myBpId)) &&
                        <span className='textSmall textBold customLink pr-1' onClick={() => this.setState({ assignModal: record }, () => { this.fetchAdditionalInfo(record.issueId) })}>
                           <Icon name='location arrow' />Assign
                     </span>}
                     {(record.status !== 'Closed' && this.props.calledFrom === 'myInbox' && (record.assignedToUserid && record.assignedToUserid === this.state.myUserId)) &&
                        <span className='textSmall textBold customLink' onClick={() => this.setState({ closeIssueModal: record }, () => { this.fetchAdditionalInfo(record.issueId) })}>
                           <Icon name='close' />Close
                     </span>}
                  </>
               }
            }
         },

      ]


      return (
         <>
            {/* <PageHeader /> */}
            <DisplayMessage context={this} messageType={this.state.messageType} messageHeader={this.state.messageHeader} message={this.state.message} />
            <Segment basic className='p-0 mt-0' loading={this.state.isFetching}>
               <div style={{ padding: '0px 0', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <span style={{ display: 'contents', fontSize: '1.2em', fontWeight: '500' }}>
                     Showing {this.state.currentPageDataLength > 0 ? this.state.currentDataCount : '0'} {this.state.currentDataCount !== this.state.dataLength && `of ${this.state.dataLength}`} Service Requests
                  </span>
                  <div>
                     <span style={{ marginRight: '30px' }}>
                        {/* <span style={fLegend}><Icon color='blue' name='flask' /> Evaluation Required </span> */}
                     </span>

                     {/* {this.state.data.length > 0 && <JsonToCsv data={this.state.data} filename={filename} fields={fields} style={style} text={text} />} */}
                     {/* <Button icon='download' content='Download' className='mr-0 customBtn1Secondary mr-1' compact size='small' onClick={() => this.downloadAllDcns()} /> */}
                     <Button icon='redo' content={t('reload')} className='customBtn1Secondary mb-5px' compact size='small' onClick={() => this.pageReload()} />
                     <Button icon='undo' content={t('resetFiltersAndSorters')} className='mr-0 customBtn1Secondary mb-5px' compact size='small' onClick={this.clearAll} />
                  </div>
               </div>

               <Table
                  size="middle"
                  columns={columns}
                  rowKey={obj => obj.issueId}
                  dataSource={this.state.data}
                  onChange={this.handleChange}
                  loading={{
                     indicator: <div><Loader active size='small' /></div>,
                     spinning: this.state.isFetching
                  }}
                  locale={{ emptyText: 'No Data' }}
                  className='border-1 bgWhite scrollableTable'
                  // scroll={{ x: 'calc(600px + 50%)' }}
                  scroll={{ x: 1160 }}
                  rowClassName={(record, index) => index % 2 === 0 ? 'bgWhite' : 'bgWhite'}
                  pagination={{ showTotal: (total, range) => this.state.currentDataCount !== total && this.setState({ currentDataCount: total }), defaultPageSize: 10, showSizeChanger: true, size: 'default', pageSizeOptions: utility.getAntdTablePageOptions(this.dataLength) }}
                  footer={currentPageData => {
                     this.state.currentPageDataLength !== currentPageData.length && this.setState({ currentPageDataLength: currentPageData.length })
                     return null;
                  }}
               />
            </Segment>

            {/* View Detail Modal */}
            {this.state.viewIssueModal && <Modal centered={false} style={{ position: 'relative' }} open={this.state.viewIssueModal && true} dimmer='inverted'>
               <Modal.Content>
                  <div className='textBig textBold mb-1 pb-5px colPrimary'>Service Request Details  <span className='colGrey40 textThin textSmall'>#{this.state.viewIssueModal.issueId}</span></div>

                  <IssueDetails t={t} data={this.state.viewIssueModal} {...this.props} />

                  <Grid columns={1}>
                     <Grid.Column computer={16} verticalAlign='bottom' className='py-5px'>
                        <div className='contentLabel'>{t('uploadedFiles')}</div>
                        <Segment basic className='p-0' loading={this.state.isFetchingFiles}>
                           {(this.state.uploadedFileList && this.state.uploadedFileList.length > 0) ?
                              this.state.uploadedFileList.map((item, key) => {
                                 return (
                                    <div key={key} onClick={() => this.downloadFile(this.state.viewIssueModal.issueId, item.name)} className='px-5px text-center cursorPointer' style={{ display: 'inline-block' }}>
                                       <Icon name='file' size='large' className='colPrimary' />
                                       <p> <span title={item.name}>{utility.elipses(item.name, 16)}</span> </p>
                                    </div>
                                 )
                              })
                              :
                              <p>No Files Uploaded</p>
                           }
                        </Segment>
                     </Grid.Column>
                     {this.state.viewIssueModal.remark && <>
                        <Grid.Column className='py-5px'>
                           <div className='contentLabel'>{t('Remark')}</div>
                           <div className='textNormal'>
                              {this.state.viewIssueModal.repliedByUserName && <span className='textBold colPrimary'>{this.state.viewIssueModal.repliedByUserName}: </span>}
                              {this.state.viewIssueModal.remark}
                           </div>
                        </Grid.Column>
                     </>}
                     <Grid.Column computer={16} verticalAlign='bottom' className='py-5px'>
                        <IssueHistory t={t} context={this} data={this.state.viewIssueModal} {...this.props} />
                     </Grid.Column>
                  </Grid>

               </Modal.Content>
               <Modal.Actions>
                  <Button icon='cancel' className='customBtn1Secondary' onClick={() => this.setState({ viewIssueModal: false })}>{t('cancel')}</Button>
                  {(this.state.viewIssueModal.status !== 'Closed' && this.props.calledFrom === 'myInbox' && ((this.state.viewIssueModal.assignedToUserid && this.state.viewIssueModal.assignedToUserid === this.state.myUserId) || (this.state.viewIssueModal.createdBy === this.state.myUserId))) &&
                     <Button className='customBtn1Primary' compact onClick={() => this.setState({ helpdeskDetailModal: this.state.viewIssueModal, viewIssueModal: false }, () => { this.fetchAdditionalInfo(this.state.helpdeskDetailModal.issueId) })}>Reply</Button>
                  }
                  {(this.state.viewIssueModal.status !== 'Closed' && (this.state.viewIssueModal.assignedToUserid ? this.state.viewIssueModal.assignedToUserid === this.state.myUserId : this.state.viewIssueModal.assignedToBpid === this.state.myBpId)) &&
                     <Button className='customBtn1Primary' compact onClick={() => this.setState({ assignModal: this.state.viewIssueModal, viewIssueModal: false }, () => { this.fetchAdditionalInfo(this.state.assignModal.issueId) })}>Assign</Button>
                  }
                  {(this.state.viewIssueModal.status !== 'Closed' && this.props.calledFrom === 'myInbox' && (this.state.viewIssueModal.assignedToUserid && this.state.viewIssueModal.assignedToUserid === this.state.myUserId)) &&
                     <Button className='customBtn1Primary' compact onClick={() => this.setState({ closeIssueModal: this.state.viewIssueModal, viewIssueModal: false }, () => { this.fetchAdditionalInfo(this.state.closeIssueModal.issueId) })}>Close Service Request</Button>
                  }
               </Modal.Actions>
            </Modal>}

            {/* Reply Modal */}
            {this.state.helpdeskDetailModal && <Modal centered={false} style={{ position: 'relative' }} open={this.state.helpdeskDetailModal && true} dimmer='inverted'>
               <Modal.Content>
                  <div className='textBig textBold mb-1 pb-5px colPrimary'>Reply to Service Request <span className='colGrey40 textThin textSmall'>#{this.state.helpdeskDetailModal.issueId}</span></div>

                  <IssueDetails t={t} data={this.state.helpdeskDetailModal} {...this.props} />

                  <Grid columns={1}>
                     <Grid.Column computer={16} verticalAlign='bottom' className='py-5px'>
                        <div className='contentLabel'>{t('uploadedFiles')}</div>
                        <Segment basic className='p-0' loading={this.state.isFetchingFiles}>
                           {(this.state.uploadedFileList && this.state.uploadedFileList.length > 0) ?
                              this.state.uploadedFileList.map((item, key) => {
                                 return (
                                    <div key={key} onClick={() => this.downloadFile(this.state.helpdeskDetailModal.issueId, item.name)} className='px-5px text-center cursorPointer' style={{ display: 'inline-block' }}>
                                       <Icon name='file' size='large' className='colPrimary' />
                                       <p> <span title={item.name}>{utility.elipses(item.name, 16)}</span> </p>
                                    </div>
                                 )
                              })
                              :
                              <p>No Files Uploaded</p>
                           }
                        </Segment>
                     </Grid.Column>
                     {this.state.helpdeskDetailModal.remark && <>
                        <Grid.Column className='py-5px'>
                           <div className='contentLabel'>{t('Remark')}</div>
                           <div className='textNormal'>
                              {this.state.helpdeskDetailModal.repliedByUserName && <span className='textBold colPrimary'>{this.state.helpdeskDetailModal.repliedByUserName}: </span>}
                              {this.state.helpdeskDetailModal.remark}
                           </div>
                        </Grid.Column>
                     </>}
                     <Grid.Column computer={16} verticalAlign='bottom' className='py-5px'>
                        <IssueHistory t={t} context={this} data={this.state.helpdeskDetailModal} {...this.props} />
                     </Grid.Column>
                  </Grid>
                  <Grid>
                     <Grid.Column>
                        <Form>
                           <Form.Field required>
                              <label>{t('remark')}</label>
                              <Form.TextArea rows={2} placeholder={t('remark')} value={this.state.remark} onChange={(e) => this.setState({ remark: e.target.value })} />
                              <div className='validationInfoText'>Disclaimer : Text written in this field will be visible to the person who raised the service request.</div>
                              <div className='validationInfoText'>{t('characterLimitValidationRule', { from: '10', to: '2000' })}</div>
                           </Form.Field>
                        </Form>
                     </Grid.Column>
                  </Grid>
                  <Divider hidden />
               </Modal.Content>
               <Modal.Actions>
                  <Button icon='cancel' className='customBtn1Secondary' onClick={() => this.setState({ helpdeskDetailModal: false, remark: '' })}>{t('cancel')}</Button>
                  <Button className='customBtn1Primary' compact loading={this.state.isSubmittingResponse} onClick={() => this.setState({ confirmResponseModal: true })}>Reply</Button>
               </Modal.Actions>
            </Modal>}
            <Confirm
               size='tiny' open={this.state.confirmResponseModal && true}
               content={<div className='textMedium px-1 py-2'>Are you sure you want to reply?</div>}
               onCancel={() => this.setState({ confirmResponseModal: false })}
               onConfirm={() => this.submitResponse(this.state.helpdeskDetailModal.issueId)} cancelButton={<Button className='customBtn1Secondary'>Cancel</Button>} confirmButton={<Button className='customBtn1Primary'>OK</Button>}
            />

            {/* Assign Modal */}
            {this.state.assignModal && <Modal centered={false} style={{ position: 'relative' }} open={this.state.assignModal && true} dimmer='inverted'>
               <Modal.Content>
                  <div className='textBig textBold mb-1 pb-5px colPrimary'>Assign Service Request <span className='colGrey40 textThin textSmall'>#{this.state.assignModal.issueId}</span></div>
                  <IssueDetails t={t} data={this.state.assignModal} {...this.props} />
                  <Grid columns={1}>
                     <Grid.Column computer={16} verticalAlign='bottom' className='py-5px'>
                        <div className='contentLabel'>{t('uploadedFiles')}</div>
                        <Segment basic className='p-0' loading={this.state.isFetchingFiles}>
                           {(this.state.uploadedFileList && this.state.uploadedFileList.length > 0) ?
                              this.state.uploadedFileList.map((item, key) => {
                                 return (<div key={key} onClick={() => this.downloadFile(this.state.assignModal.issueId, item.name)} className='px-5px text-center cursorPointer' style={{ display: 'inline-block' }}>
                                    <Icon name='file' size='large' className='colPrimary' />
                                    <p> <span title={item.name}>{utility.elipses(item.name, 16)}</span> </p>
                                 </div>)
                              })
                              :
                              <p>No Files Uploaded</p>
                           }
                        </Segment>
                     </Grid.Column>
                     <Grid.Column computer={16} verticalAlign='bottom' className='py-5px'>
                        <IssueHistory t={t} context={this} data={this.state.assignModal} {...this.props} />
                     </Grid.Column>

                     {this.state.assignModal.remark && <>
                        <Grid.Column className='py-5px'>
                           <div className='contentLabel'>{t('Remark')}</div>
                           <div className='textNormal'>
                              {this.state.assignModal.repliedByUserName && <span className='textBold colPrimary'>{this.state.assignModal.repliedByUserName}: </span>}
                              {this.state.assignModal.remark}
                           </div>
                        </Grid.Column>
                     </>}
                  </Grid>
                  <Grid columns={1}>
                     <Grid.Column className='py-5px'>
                        <Form><Form.Field required>
                           <label>{t('issueAssignment')}</label>
                           <Form.Group inline>
                              {this.state.assignModal.assignedToUserid !== this.state.myUserId ?
                                 <Form.Radio label={`Assign to myself (${utility.fetchUserFirstName()})`}
                                    value='self' checked={this.state.assignTo === 'self'} onChange={(e, { value }) => this.setState({ assignTo: value })} />
                                 :
                                 <Form.Radio label={`Assign to my organisation (${this.props.fetchOrganizationName(utility.fetchUserBpid())})`}
                                    value='myCouncil' checked={this.state.assignTo === 'myCouncil'} onChange={(e, { value }) => this.setState({ assignTo: value })} />
                              }
                              <Form.Radio label={`Assign to another user in ${this.props.fetchOrganizationName(utility.fetchUserBpid())}`}
                                 value='user' checked={this.state.assignTo === 'user'} onChange={(e, { value }) => this.setState({ assignTo: value })} />
                              <Form.Radio label='Assign to another organisation'
                                 value='council' checked={this.state.assignTo === 'council'} onChange={(e, { value }) => this.setState({ assignTo: value })} />
                           </Form.Group>
                        </Form.Field></Form>
                     </Grid.Column>
                     {this.state.assignTo === 'user' &&
                        <Grid.Column className='py-5px'>
                           <Form><Form.Field required>
                              <label>{t('user')}</label>
                              <Select search rows={2} placeholder={t('user')} options={this.state.myOrgUserOptions} value={this.state.assignToUserId} onChange={(e, { value }) => this.setState({ assignToUserId: value })} />
                           </Form.Field></Form>
                        </Grid.Column>}
                     {this.state.assignTo === 'council' &&
                        <Grid.Column className='py-5px'>
                           <Form><Form.Field required>
                              <label>{t('organisation')}</label>
                              <Select search rows={2} placeholder={t('organisation')} options={this.state.assignToOptions} value={this.state.assignToBpid} onChange={(e, { value }) => this.setState({ assignToBpid: value })} />
                           </Form.Field></Form>
                        </Grid.Column>}

                     {this.state.assignTo !== 'self' && <Grid.Column className='py-5px'>
                        <Form>
                           <Form.Field required>
                              <label>{t('remark')}</label>
                              <TextArea rows={2} placeholder={t('remark')} value={this.state.reassignRemark} onChange={(e) => this.setState({ reassignRemark: e.target.value })} />
                           </Form.Field>
                           <div className='validationInfoText'>Disclaimer : Text written in this field will be visible to the person who raised the service request.</div>
                           <div className='validationInfoText'>{t('characterLimitValidationRule', { from: '10', to: '2000' })}</div>
                        </Form>
                     </Grid.Column>}
                  </Grid>

                  <Divider hidden />
               </Modal.Content>
               <Modal.Actions>
                  <Button icon='cancel' className='customBtn1Secondary' onClick={() => this.setState({ assignModal: false, assignTo: '', assignToBpid: '', assignToUserId: '', reassignRemark: '' })}>{t('cancel')}</Button>
                  <Button className='customBtn1Primary' compact loading={this.state.isAssigningTicket} onClick={() => this.setState({ confirmAssignModal: true })}>Assign</Button>
               </Modal.Actions>
            </Modal>}
            <Confirm
               size='tiny' open={this.state.confirmAssignModal && true}
               content={<div className='textMedium px-1 py-2'>Are you sure you want to assign this service request?</div>}
               onCancel={() => this.setState({ confirmAssignModal: false })}
               onConfirm={() => this.assignTicket(this.state.assignModal.issueId)} cancelButton={<Button className='customBtn1Secondary'>Cancel</Button>} confirmButton={<Button className='customBtn1Primary'>OK</Button>}
            />
            {/* Close Modal */}
            {this.state.closeIssueModal && <Modal centered={false} style={{ position: 'relative' }} open={this.state.closeIssueModal && true} dimmer='inverted'>
               <Modal.Content>
                  <div className='textBig textBold mb-1 pb-5px colPrimary'>Close Service Request <span className='colGrey40 textThin textSmall'>#{this.state.closeIssueModal.issueId}</span></div>
                  <IssueDetails t={t} data={this.state.closeIssueModal} {...this.props} />
                  <Grid columns={1}>
                     <Grid.Column computer={16} verticalAlign='bottom' className='py-5px'>
                        <div className='contentLabel'>{t('uploadedFiles')}</div>
                        <Segment basic className='p-0' loading={this.state.isFetchingFiles}>
                           {(this.state.uploadedFileList && this.state.uploadedFileList.length > 0) ?
                              this.state.uploadedFileList.map((item, key) => {
                                 return (
                                    <div key={key} onClick={() => this.downloadFile(this.state.closeIssueModal.issueId, item.name)} className='px-5px text-center cursorPointer' style={{ display: 'inline-block' }}>
                                       <Icon name='file' size='large' className='colPrimary' />
                                       <p> <span title={item.name}>{utility.elipses(item.name, 16)}</span>                                       </p>
                                    </div>
                                 )
                              })
                              :
                              <p>No Files Uploaded</p>
                           }
                        </Segment>
                     </Grid.Column>
                     <Grid.Column computer={16} verticalAlign='bottom' className='py-5px'>
                        <IssueHistory t={t} context={this} data={this.state.closeIssueModal} {...this.props} />
                     </Grid.Column>

                     {this.state.closeIssueModal.remark && <>
                        <Grid.Column className='py-5px'>
                           <div className='contentLabel'>{t('Remark')}</div>
                           <div className='textNormal'>
                              {this.state.closeIssueModal.repliedByUserName && <span className='textBold colPrimary'>{this.state.closeIssueModal.repliedByUserName}: </span>}
                              {this.state.closeIssueModal.remark}
                           </div>
                        </Grid.Column>
                     </>}
                  </Grid>


                  <Grid columns={1}>
                     <Grid.Column className='py-5px'>
                        <Form>
                           <Form.Field required>
                              <label>{t('closureRemark')}</label>
                              <TextArea rows={2} placeholder={t('remark')} value={this.state.closingRemark} onChange={(e) => this.setState({ closingRemark: e.target.value })} />
                           </Form.Field>
                           <div className='validationInfoText'>Disclaimer : Text written in this field will be visible to the person who raised the service request.</div>
                           <div className='validationInfoText'>{t('characterLimitValidationRule', { from: '10', to: '2000' })}</div>
                        </Form>
                     </Grid.Column>
                  </Grid>

                  <Divider hidden />
               </Modal.Content>
               <Modal.Actions>
                  <Button icon='cancel' className='customBtn1Secondary' onClick={() => this.setState({ closeIssueModal: false, assignTo: '', assignToBpid: '', assignToUserId: '', closingRemark: '' })}>Cancel</Button>
                  <Button className='customBtn1Primary' compact loading={this.state.isClosingTicket} onClick={() => this.setState({ confirmCloseIssueModal: true })}>Close Service Request</Button>
               </Modal.Actions>
            </Modal>}
            <Confirm
               size='tiny' open={this.state.confirmCloseIssueModal && true}
               content={<div className='textMedium px-1 py-2'>Are you sure you want to close this service request?</div>}
               onCancel={() => this.setState({ confirmCloseIssueModal: false })}
               onConfirm={() => this.closeIssue(this.state.closeIssueModal.issueId)} cancelButton={<Button className='customBtn1Secondary'>Cancel</Button>} confirmButton={<Button className='customBtn1Primary'>OK</Button>}
            />




         </>
      );

   }

}

export default withTranslation()(withRouter(InboxTab))



const IssueDetails = ({ t, data, fetchOrganizationName }) => {
   return (
      <>
         <Grid columns='equal'>
            <Grid.Column width={4} className='py-5px'>
               <div className='contentLabel'>{t('problemRelatedTo')}</div>
               <div className='textNormal'>{data.problemName}</div>
            </Grid.Column>
            <Grid.Column width={4} className='py-5px'>
               <div className='contentLabel'>{t('createdAt')}</div>
               <div className='textNormal'>{utility.formatDate(data.createdAt)}</div>
            </Grid.Column>
            <Grid.Column width={8} className='py-5px'>
               <div className='contentLabel'>{t('status')}</div>
               <div className='textNormal'>
                  {data.status === 'Logged' && <Label empty size='tiny' color='orange' circular></Label>}
                  {data.status === 'Active' && <Label empty size='tiny' color='green' circular></Label>}
                  {data.status === 'Closed' && <Label empty size='tiny' color='red' circular></Label>}

                  <span> {data.status}</span>
               </div>
            </Grid.Column>
            {data.emailId ?
               <Grid.Column width={8} className='py-5px'>
                  <div className='contentLabel'>{t('complainant')}</div>
                  <div className='textNormal'>{data.emailId}</div>
               </Grid.Column>
               :
               <Grid.Column width={8} className='py-5px'>
                  <div className='contentLabel'>{t('complainant')}</div>
                  <div className='textNormal'>{fetchOrganizationName(data.complainantBpid)} ({data.complainantName})</div>
               </Grid.Column>
            }
            <Grid.Column width={8} className='py-5px'>
               <div className='contentLabel'>{t('assignedTo')}</div>
               <div className='textNormal'>{fetchOrganizationName(data.assignedToBpid)} {data.assignedToUserName && `(${data.assignedToUserName})`}</div>
            </Grid.Column>


            <Grid.Column width={16} className='py-5px'>
               <div className='contentLabel'>{t('problemDescription')}</div>
               <div className='textNormal'>{data.problemDetails}</div>
            </Grid.Column>
         </Grid>
      </>
   )
}
const IssueHistory = ({ t, context, data, fetchOrganizationName }) => {
   let historyList = context.state.historyList
   return (
      <>
         <CustomAccordion head={{ title: `${t('history')} (${historyList.length})`, class: 'textNormal textBold colPrimary py-5px' }}>
            <SemanticTable singleLine size='small' compact className='mt-0'>
               <SemanticTable.Header>
                  <SemanticTable.Row>
                     <SemanticTable.HeaderCell className='py-5px'>Event</SemanticTable.HeaderCell>
                     {/* <SemanticTable.HeaderCell className='py-5px'>By</SemanticTable.HeaderCell> */}
                     <SemanticTable.HeaderCell className='py-5px'>Status</SemanticTable.HeaderCell>
                     <SemanticTable.HeaderCell className='py-5px'>Created At</SemanticTable.HeaderCell>
                     <SemanticTable.HeaderCell className='py-5px'>Remark</SemanticTable.HeaderCell>
                  </SemanticTable.Row>
               </SemanticTable.Header>

               <SemanticTable.Body>
                  {historyList.length > 0 ?
                     historyList.map(item => {
                        let statement = ''
                        if (item.bpid) {
                           statement = `by ${fetchOrganizationName(item.bpid)} (${item.createdByName}) ${(item.event === 'Query assigned') ? `to ${fetchOrganizationName(item.assignedToBpid)} ${item.assignedToUserName ? `(${item.assignedToUserName})` : ''}` : ''}`
                        }
                        else {
                           statement = `by ${item.emailId}`
                        }

                        return (
                           <SemanticTable.Row key={item.issueHistoryId}>
                              <SemanticTable.Cell className='py-5px colGrey40' title={`${item.event} ${statement}`}><span className='colBlack textBold'>{item.event} </span>{utility.elipses(statement, 50)}</SemanticTable.Cell>
                              {/* <SemanticTable.Cell className='py-5px' title={`${fetchOrganizationName(item.bpid)} (${item.createdByName})`}>{fetchOrganizationName(item.bpid)} ({item.createdByName})</SemanticTable.Cell> */}
                              <SemanticTable.Cell className='py-5px' title={item.status}>{item.status}</SemanticTable.Cell>
                              <SemanticTable.Cell className='py-5px' title={utility.formatDate(item.createdAt)}>{utility.formatDate(item.createdAt)}</SemanticTable.Cell>
                              <SemanticTable.Cell className='py-5px' title={item.remark}>{utility.elipses(item.remark, 40)}</SemanticTable.Cell>
                           </SemanticTable.Row>
                        )
                     })
                     :
                     <SemanticTable.Row className='text-center'>
                        No Data
                     </SemanticTable.Row>
                  }

               </SemanticTable.Body>
            </SemanticTable>
         </CustomAccordion>
      </>
   )
}

