import React, { Component } from "react";
import { Grid, Image, Card, Container, Icon, Button, Label } from "semantic-ui-react";
import MenuHeader from "./navbar.jsx";
import Footer from "./footer.jsx";
import ajax from '../commons/agent.js';


export default class LandingPage extends Component {
  constructor(props) {
    super(props);

  }

  render() {
    return (
      <React.Fragment>
        <Grid>
          <Grid.Column width={16} className="pb-0">
            <MenuHeader />
          </Grid.Column>
        </Grid>


        <Grid columns='equal'>
          <Grid.Column className='p-0'>
            <Image src='/images/landingPgCover.jpg' style={{ height: 'calc(100vh - 158px)', width: '100%', objectFit: 'cover' }} />
            {/* <div className='colPrimaryDarker' style={{ position: "absolute", fontSize: "2.4em", fontWeight: "500", bottom: "12%", left: "50%", transform: "translateX(-50%)", padding: '14px 16px', background: 'hsl(0deg 0% 100% / 72%)', textAlign: 'center' }}>
              Scotland's Dog Content Notice Database
              for local authorities and Police Scotland.
            </div> */}

            <div style={{ position: "absolute",fontSize: "27px", fontWeight: "500",padding: '14px 16px', bottom: "73%", left: "53%", background: 'rgb(7 34 64)', transform: "translateX(-53%)", textAlign: 'center', opacity: '77%'}}>
              <span style={{ textAlign: 'center', color: 'whitesmoke' }}>One Database,</span>
              <p style={{ color: 'whitesmoke' }}>shared across Scotland.</p>
            </div>

            <div style={{ position: "absolute", padding: '14px 16px', bottom: "12%", left: "53%", background: 'rgb(7 34 64)', transform: "translateX(-53%)",textAlign: 'center',  opacity: '77%'}}>
              <span style={{ fontWeight: "500", fontSize: "26px", color: 'whitesmoke' }}>Scotland's Dog Control Notice Database</span>
              <p style={{ color: 'whitesmoke', fontSize: '20px' }}>for local authorities and Police Scotland.</p>
            </div>
          </Grid.Column>
        </Grid>




        <Footer />
      </React.Fragment>
    );
  }
}
