import React from 'react'
import { withRouter, Link, Redirect } from 'react-router-dom';
import utility from '../../commons/utility.js';
import ajax from '../../commons/agent.js';
import { Button, Input, Segment, Tab } from 'semantic-ui-react';
import { Table } from 'antd';
import PageHeader from '../PageHeader.jsx';
import { withTranslation } from 'react-i18next';
import DisplayMessage from '../../commons/displayMessage.jsx';
import InboxTab from './InboxTab.jsx';

class HelpdeskQueryDashboard extends React.Component {

   constructor(props) {
      super(props);
      this.state = {
         messageType: '', messageHeader: '', message: [],

      };
   }
   componentDidMount() {
      const myOrgCategory = this.props.fetchOrganizationCategory(utility.fetchUserBpid())
      this.setState({ myOrgCategory })
   }


   render() {
      const { t } = this.props
      const panes = [
         { menuItem: { key: 'Group Inbox', icon: 'inbox', content: 'Group Inbox' }, render: () => <Tab.Pane className='p-1'><InboxTab calledFrom='groupInbox' {...this.props} /> </Tab.Pane> },
         { menuItem: { key: 'My Inbox', icon: 'inbox', content: 'My Inbox' }, render: () => <Tab.Pane className='p-1'><InboxTab calledFrom='myInbox' {...this.props} /> </Tab.Pane> },
         { menuItem: { key: 'Raise Issue', icon: 'add', content: 'Raise Issue', className: 'right customBtn1Primary mr-0 mb-5px' }, render: () => <Tab.Pane><Redirect to='/dashboard/helpdeskQuery' /> </Tab.Pane> },
      ]

      return (
         <>
            <PageHeader />
            <DisplayMessage context={this} messageType={this.state.messageType} messageHeader={this.state.messageHeader} message={this.state.message} />
            <Segment basic className='py-0' >
               <Tab panes={panes} className="tabMenu" />
            </Segment>


         </>
      );

   }

}

export default withTranslation()(withRouter(HelpdeskQueryDashboard))
